export const firebaseConfig = {
  // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // authDomain: "your_auth_domain",
  // projectId: "your_projectId",
  // storageBucket: "your_storage_bucket",
  // messagingSenderId: "your_messaging_sender_id",
  // appId: process.env.REACT_APP_FIREBASE_APP_ID


  // apiKey: "AIzaSyAD2ninCJoIxYfGjeSimB2gtmY0FoYdFT4",
  // authDomain: "conferenceroomreservatio-45d4b.firebaseapp.com",
  // databaseURL: "https://conferenceroomreservatio-45d4b-default-rtdb.firebaseio.com",
  // projectId: "conferenceroomreservatio-45d4b",
  // storageBucket: "conferenceroomreservatio-45d4b.appspot.com",
  // messagingSenderId: "102111322889",
  // appId: "1:102111322889:web:554df955691757b212e9d8",
  // measurementId: "G-2BRV4QX5E5",

  apiKey: "AIzaSyBA8_gv78C5CkFs3sxfUjcKuqZCoJE_x7c",
  authDomain: "base-2023-08.firebaseapp.com",
  projectId: "base-2023-08",
  storageBucket: "base-2023-08.appspot.com",
  messagingSenderId: "736014264888",
  appId: "1:736014264888:web:b4f99528527629440314cd",
  // measurementId: "G-QBS4R74SFJ"
};